<template>
  <div eagle-form-item="item-list">
    <v-card
      class="mx-auto"
      max-width="100%"
    >
      <v-toolbar
        color="accent"
        height="45"
      >
        <v-spacer></v-spacer>

        <!-- 新增項目 -->
        <v-btn
          v-if="canAddItem"
          @click="addItem"
          small
          icon
          class="white--text mr-1"
        >
          <v-icon>fa fa-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- 物件列表 -->
      <v-list
        subheader
        max-height="300"
        min-height="100"
        two-line
        class="overflow-auto"
      >
        <v-subheader v-if="header">
          {{header}}
        </v-subheader>

        <div>
          <item
            v-for="(item, index) in data"
            @remove="remove"
            :key="index"
            :index="index"
            :item="item"
            :formDataItem="formDataItem"
            :readonly="readonly"
            :disabled="disabled"
          ></item>

          <v-list-item v-if="data.length == 0" class="mt-4">
            <v-list-item-content>
              <v-list-item-title class="grey--text text-center">
                {{'data.empty'| t}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data: () => ({
    data: [],
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(!Array.isArray(this.storeData)) {
        this.data = []
        return
      }
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    remove(index) {
      this.$delete(this.data, index)
    },
    addItem() {
      this.selector({
        max: 0,
        multiple: true,
        usedList: this.data,
        applyCallback: (data) => {
          let items = window.eagleLodash.cloneDeep(this.data)
          items = items.concat(data)
          this.data = items
        },
        closeCallback: async () => {
          await this.$nextTick()
        },
      })
    },
  },
  computed: {
    canAddItem() {
      if(this.readonly) return false
      if(this.disabled) return false
      if(typeof this.selector != 'function') return false
      return true
    },
    selector() {
      return this.formDataItem.selector
    },
    header() {
      if(!this.formDataItem.header) return null
      return this.$t(this.formDataItem.header)
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
  components: {
    item: () => import('./itemList/item.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
